export const USE_DEV_MODE = import.meta.env.VITE_DEV_MODE === "true";
export const USE_MOCKS = import.meta.env.MODE === "mock";
export const USE_STAFF_MODE = import.meta.env.MODE === "staff";

export const IS_DEV = import.meta.env.DEV;
export const IS_PROD = import.meta.env.PROD;

export const PACKAGE_VERSION = import.meta.env.PACKAGE_VERSION;
export const POSTHOG_KEY = "phc_5Ye2r14imIkan5Ea4FURNXcLdrUt6Ztf6ouzNppU5cn";
export const POSTHOG_DEV_KEY =
  "phc_1SUEXzRAezj8infuQ9i1DGZdpmrY8uPvXWPeoXjpJDx";
export const POSTHOG_HOST = "https://ph.wayflyer.com";
