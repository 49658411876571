import { RouteObject } from "react-router-dom";
import { lazy } from "react";

import { Staff } from "../../../screens/staff";
import { Public } from "../wrappers/public";
import { Protected } from "../wrappers/protected";
import { ROUTES, routeWrapper, sharedRoutes } from "../sharedRoutes";

const AuthenticatedStaff = lazy(
  () => import("../../../screens/authenticatedStaff"),
);
const RedirectStaff = lazy(() => import("../../../screens/redirectStaff"));

export const staffRoutes: RouteObject[] = [
  {
    path: ROUTES.LOGIN,
    element: routeWrapper({
      component: Staff,
      wrapper: Public,
    }),
  },
  {
    path: ROUTES.SUCCESS,
    element: routeWrapper({
      component: AuthenticatedStaff,
      wrapper: Protected,
    }),
  },
  {
    path: ROUTES.REDIRECT,
    element: routeWrapper({
      component: RedirectStaff,
      wrapper: Protected,
    }),
  },
  ...sharedRoutes,
];
