import { useAuth } from "@wayflyer/auth-sdk";
import { useEffect } from "react";
import { Loading } from "@wayflyer/auth-ui";

import { USE_DEV_MODE } from "../../utils/env";

import { StaffDevMode } from "./staff-dev-mode";

/**
 * Staff Okta Signin Redirection
 */
const _Staff = (): JSX.Element => {
  const auth = useAuth();
  useEffect(() => {
    if (auth && !auth.isLoading) {
      auth.oktaSignin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isLoading]);

  return <Loading />;
};

export const Staff = (): JSX.Element => {
  if (USE_DEV_MODE) {
    return <StaffDevMode />;
  }

  return <_Staff />;
};
