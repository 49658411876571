import * as Yup from "yup";
import type { IntlShape } from "react-intl";

export const MIN_PASSWORD_LENGTH = 12;

const emailValidationMessage = (intl: IntlShape) =>
  intl.formatMessage({
    id: "auth: auth email validate message",
    defaultMessage: "Please enter a valid work email address.",
  });

export const nameAuthValidationSchema = (intl: IntlShape) => ({
  first_name: Yup.string().required(
    intl.formatMessage({
      id: "auth: auth first name is required",
      defaultMessage: "First name cannot be empty.",
    }),
  ),
  last_name: Yup.string().required(
    intl.formatMessage({
      id: "auth: auth last name is required",
      defaultMessage: "Last name cannot be empty.",
    }),
  ),
});

export const emailAuthValidationSchema = (intl: IntlShape) => ({
  email: Yup.string()
    .trim()
    .email(emailValidationMessage(intl))
    .lowercase()
    .required(emailValidationMessage(intl)),
});

export const passwordAuthValidationSchema = (
  intl: IntlShape,
  minPasswordLength: boolean,
) => {
  let passwordSchema = Yup.string().required(
    intl.formatMessage({
      id: "auth: auth password is required",
      defaultMessage: "Password can not be empty.",
    }),
  );
  if (minPasswordLength) {
    passwordSchema = passwordSchema.min(
      MIN_PASSWORD_LENGTH,
      intl.formatMessage(
        {
          id: "auth: auth password is too short",
          defaultMessage:
            "Password must contain at least {minPasswordLength} characters.",
        },
        {
          minPasswordLength: MIN_PASSWORD_LENGTH,
        },
      ),
    );
  }

  return {
    password: passwordSchema,
  };
};
