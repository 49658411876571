import { useState } from "react";

/**
 * Wraps a submit handler with a loading flag
 */
export const useOnFinish = (
  submitClickHandler: (formData: any) => Promise<void>,
): [(formData: any) => Promise<void>, boolean] => {
  const [loading, setLoading] = useState(false);
  const onFinish = async (formData: any) => {
    setLoading(true);
    try {
      await submitClickHandler(formData);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setLoading(false);
  };

  return [onFinish, loading];
};
