import { FlyUIProvider } from "@wayflyer/flyui";
import React from "react";
import ReactDOM from "react-dom";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";

import English from "../compiled-lang/en.json";
import German from "../compiled-lang/de.json";
import Spanish from "../compiled-lang/es.json";
import thirdPartyConfig from "../config.third-party.json";

import { USE_MOCKS } from "./utils/env";
import App from "./App";
import { Plugins } from "./plugins/Plugins";

const getLocale = () => navigator.language;

const loadMessages = () => {
  const locale = getLocale();
  if (locale.startsWith("es")) {
    return Spanish;
  } else if (locale.startsWith("de")) {
    return German;
  }
  return English;
};

const intl = createIntl(
  {
    messages: loadMessages(),
    locale: getLocale(),
    defaultLocale: "en",
  },
  createIntlCache(),
);

const initialiseMocks = async (): Promise<void> => {
  const { setupWorker } = await import("msw/browser");
  const { handlers } = await import("@wayflyer/auth-mocks");

  const worker = setupWorker(...(handlers as any));
  worker.start({ onUnhandledRequest: "warn" });
};

const config = (thirdPartyConfig as any)[location.hostname];

const renderApp = () => {
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <React.StrictMode>
      <RawIntlProvider value={intl}>
        <Plugins {...config}>
          <BrowserRouter>
            <FlyUIProvider
              theme="wayflyer"
              intl={intl}
              auth={{
                username: "Staging Test User",
                logoutClickHandler: () => {},
                customerInactive: false,
              }}
              navigation={{
                selected: "/",
                onClick: () => {},
                navItems: [],
              }}
            >
              <App />
            </FlyUIProvider>
          </BrowserRouter>
        </Plugins>
      </RawIntlProvider>
    </React.StrictMode>,
    document.getElementById("root"),
  );
};

USE_MOCKS ? initialiseMocks().then(renderApp) : renderApp();
