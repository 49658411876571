import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

export const NEXT_KEY = "next";

export interface GoNextParams {
  /**
   * Default path to redirect to if the next page is not set.
   * @default "/"
   */
  defaultPath?: string;

  /**
   * Override the host to redirect to.
   *
   * @default current host
   */
  host?: string;

  /**
   * Do nothing if the next page is not set, rather than redirecting to the
   * root.
   */
  onlyIfSet?: boolean;
}

/**
 * Redirect to the next stored page, or root if not set.
 *
 * @param params see `GoNextParams`
 */
export type GoNext = (params?: GoNextParams) => void;

/**
 * Hook that returns a function to redirect to the next page.
 *
 * The next page is stored in session storage via the `next` query parameter
 * to persist through authentication flows, etc.
 */
export const useNext = (): GoNext => {
  const [searchParams] = useSearchParams();
  const nextParam = searchParams.get(NEXT_KEY);
  if (nextParam) {
    window.sessionStorage.setItem(NEXT_KEY, nextParam);
  }

  return useCallback<GoNext>((params?: GoNextParams) => {
    const next = window.sessionStorage.getItem(NEXT_KEY);
    if (params?.onlyIfSet && !next) {
      return;
    }

    // Strip trailing slashes from host and add a leading slash to the path.
    const host = (params?.host || window.location.origin).replace(/\/+$/, "");
    const path = next
      ? "/" + next.replace(/^\/+/, "")
      : params?.defaultPath || "/";

    window.sessionStorage.removeItem(NEXT_KEY);
    window.open(`${host}${path}`, "_self");
  }, []);
};
