export const isLocalhost = () =>
  window.location.hostname === "localhost" ||
  window.location.hostname === "[::1]" ||
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
  );

export const isProd =
  Boolean(window.location.hostname === "app.wayflyer.com") ||
  Boolean(window.location.hostname === "app.wayflyer.team");
