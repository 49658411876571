import { PrimarySecondaryPage, AppShell } from "@wayflyer/flyui";
import { useIntl } from "react-intl";

import type { SignInProps } from "../interface";
import { LoginForm } from "../../components/login-form";
import { AuthMarketingPanel } from "../../components/auth-marketing-assets";

export const Signin = ({
  logoHref,
  googleButtonClickHandler,
  submitClickHandler,
  linkClickHandler,
  forgotPasswordClickHandler,
  shouldAnimate,
  error,
  oauthError,
}: SignInProps) => {
  const intl = useIntl();
  return (
    <AppShell skeleton logoLink={{ url: logoHref }}>
      <PrimarySecondaryPage
        hero={{ animateOverlay: !!shouldAnimate }}
        title={intl.formatMessage({
          id: "auth: login title",
          defaultMessage: "Sign in to Wayflyer",
        })}
        primaryContent={
          <LoginForm
            googleButtonClickHandler={googleButtonClickHandler}
            submitClickHandler={submitClickHandler}
            linkClickHandler={linkClickHandler}
            logoHref={logoHref}
            forgotPasswordClickHandler={forgotPasswordClickHandler}
            error={error}
            oauthError={oauthError}
            hideForgotPasswordLink={false}
          />
        }
        secondaryContent={<AuthMarketingPanel />}
      />
    </AppShell>
  );
};
