import { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { Helmet } from "react-helmet";

import devIcon from "./assets/images/wayflyer-app-favicon-dev.png";
import prodIcon from "./assets/images/wayflyer-app-favicon.png";
import darkModeProdIcon from "./assets/images/wayflyer-app-favicon-white.png";
import { StaffRoutes } from "./components/routes/staff";
import { UnifiedRoutes } from "./components/routes/unifiedRoutes";
import { useNext } from "./hooks/useNext";
import { IS_DEV, USE_STAFF_MODE } from "./utils/env";

function App() {
  useNext();
  useEffect(() => {
    if (!window.localStorage.getItem("userId")) {
      window.localStorage.setItem("userId", uuid());
    }
  }, []);

  return (
    <>
      {IS_DEV ? (
        <Helmet>
          <link type="image/png" rel="icon" href={devIcon}></link>
        </Helmet>
      ) : (
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            media="(prefers-color-scheme: light)"
            href={prodIcon}
          ></link>
          <link
            rel="icon"
            type="image/png"
            media="(prefers-color-scheme: dark)"
            href={darkModeProdIcon}
          ></link>
        </Helmet>
      )}

      {USE_STAFF_MODE ? <StaffRoutes /> : <UnifiedRoutes />}
    </>
  );
}

export default App;
