import { useRoutes } from "react-router-dom";

import { StaffRoutes } from "./staff";
import { CustomerRoutes } from "./customer";

export const UnifiedRoutes = () => {
  const rendered = useRoutes([
    {
      path: "/auth/staff/*",
      element: <StaffRoutes />,
    },
    {
      path: "/*",
      element: <CustomerRoutes />,
    },
  ]);

  return rendered;
};
