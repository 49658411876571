import { useIntl, FormattedMessage } from "react-intl";
import { useForm, UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object as YupObject } from "yup";
import { AnimatePresence, domAnimation, LazyMotion, m } from "framer-motion";
import {
  Banner,
  FormLayout,
  GoogleButton,
  Form,
  Flex,
  useConstrainedWidth,
  Button,
  animationParams,
} from "@wayflyer/flyui";
import { useLocation } from "react-router-dom";
import { RhfTextField } from "@wayflyer/flyui-hook-form";
import { useState } from "react";

import { useOnFinish } from "../../hooks/useOnFinish";
import {
  emailAuthValidationSchema,
  passwordAuthValidationSchema,
} from "../../utils/authValidationSchema";
import type { AuthFormProps } from "../signup-form/signup-form.types";
import { NotificationBanner } from "../notification-banner";
import { GoogleButtonDivider } from "../signup-form/signup-form.elements";

import { LoginFormLinks } from "./login-form-links";

export interface LoginFormProps extends AuthFormProps {
  submitClickHandler: (formData: any) => Promise<void>;
  forgotPasswordClickHandler?: (email?: string) => void;
  googleButtonClickHandler?: () => void;
  hideForgotPasswordLink?: boolean;
}

export interface _LoginFormProps extends LoginFormProps {
  error?: any;
  form: UseFormReturn<LoginFormFields>;
}

export type LoginFormFields = { email: string; password: string };

export const _LoginForm = ({
  error,
  submitClickHandler,
  forgotPasswordClickHandler,
  googleButtonClickHandler,
  linkClickHandler,
  oauthError,
  form,
  hideForgotPasswordLink,
}: _LoginFormProps) => {
  const intl = useIntl();
  const [onFinish, loading] = useOnFinish(submitClickHandler);
  const location = useLocation();
  const maxWidth = useConstrainedWidth({ windowGt: "md" });
  const [fieldsVisible, setFieldsVisible] = useState(
    !googleButtonClickHandler || error ? true : false,
  );
  const forgotPasswordClicked = forgotPasswordClickHandler
    ? () => {
        forgotPasswordClickHandler(form.getValues("email"));
      }
    : undefined;

  const { initial, animate } = animationParams(
    { height: 0, overflow: "hidden", opacity: 0 },
    {
      height: "auto",
      overflow: "hidden",
      opacity: 1,
      transitionEnd: { overflow: "visible" },
    },
  );

  const loginAttrs = { buttonType: "login" };
  const { newPasswordSet, passwordResetRequested } =
    (location?.state as any) || {};
  return (
    <Flex direction="column" maxWidth={maxWidth}>
      <FormLayout>
        {googleButtonClickHandler ? (
          <FormLayout>
            {newPasswordSet && (
              <Banner
                variant="success"
                title={intl.formatMessage({
                  id: "auth: signin with new password title",
                  defaultMessage: "Password reset",
                })}
                data-testid="new-password-set-banner"
              >
                {intl.formatMessage({
                  id: "auth: signin with new password",
                  defaultMessage:
                    "Please sign in to your account with your new password below.",
                })}
              </Banner>
            )}
            {passwordResetRequested && (
              <Banner
                variant="success"
                title={intl.formatMessage({
                  id: "auth: signin password reset link sent title",
                  defaultMessage: "Password reset email sent",
                })}
                data-testid="new-password-request-banner"
              >
                <FormattedMessage
                  id="auth: signin password reset link sent"
                  defaultMessage="Check your inbox. {br} We'll email you a password reset link if we find your account."
                  values={{ br: <br /> }}
                />
              </Banner>
            )}
            <GoogleButton
              primary
              fullWidth
              onClick={googleButtonClickHandler}
              label={intl.formatMessage({
                id: "auth: log in with Google button",
                defaultMessage: "Continue with Google",
              })}
              analyticsAttrs={loginAttrs}
            />
            {oauthError && (
              <NotificationBanner notification={oauthError} translate />
            )}
          </FormLayout>
        ) : null}
        {!fieldsVisible ? (
          <Button
            variant="Secondary"
            size="large"
            fullWidth
            onClick={() => setFieldsVisible(true)}
          >
            Continue with email
          </Button>
        ) : googleButtonClickHandler ? (
          <GoogleButtonDivider />
        ) : null}
        <LazyMotion features={domAnimation}>
          <AnimatePresence>
            {fieldsVisible ? (
              <m.div
                initial={initial}
                animate={animate}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <Form
                  constrainWidth={{ windowGt: "md" }}
                  onSubmit={form.handleSubmit(onFinish)}
                  testId="login-form"
                  primaryAction={{
                    loading,
                    content: intl.formatMessage({
                      id: "auth: login button",
                      defaultMessage: "Sign in",
                    }),
                  }}
                  primaryActionTestId="login-button"
                  primaryActionVariant="Secondary"
                  fullWidthButtons
                  buttonSize="large"
                >
                  {error && <NotificationBanner notification={error} />}
                  <RhfTextField<LoginFormFields>
                    form={form}
                    label={intl.formatMessage({
                      id: "auth: auth email placeholder",
                      defaultMessage: "Email",
                    })}
                    name="email"
                  />

                  <RhfTextField<LoginFormFields>
                    form={form}
                    label={intl.formatMessage({
                      id: "auth: auth password placeholder",
                      defaultMessage: "Password",
                    })}
                    name="password"
                    type="password"
                  />
                </Form>
              </m.div>
            ) : null}
          </AnimatePresence>
        </LazyMotion>
        <LoginFormLinks
          linkClickHandler={linkClickHandler}
          forgotPasswordClicked={forgotPasswordClicked}
          hideForgotPasswordLink={hideForgotPasswordLink}
        />
      </FormLayout>
    </Flex>
  );
};

export const LoginForm = (props: LoginFormProps) => {
  const intl = useIntl();
  const schema = YupObject({
    ...emailAuthValidationSchema(intl),
    ...passwordAuthValidationSchema(intl, false),
  }).required();
  const form = useForm<LoginFormFields>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  return <_LoginForm {...props} form={form} />;
};
