import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { Signin as FlySignIn, useMessages } from "@wayflyer/auth-ui";
import { useAuth } from "@wayflyer/auth-sdk";
import { Helmet } from "react-helmet";

import { useLocalError } from "../../contexts/LocalErrorContext";

/**
 * Staff Dev Mode Username/Password Signin
 */
export const StaffDevMode = (): JSX.Element => {
  const auth = useAuth();
  const location = useLocation();
  const intl = useIntl();
  const errorLookup = useMessages(intl);

  const { error } = useLocalError();
  return (
    <>
      <Helmet>
        <title>Wayflyer - Staff Dev Mode Login</title>
      </Helmet>
      <FlySignIn
        logoHref="#"
        linkClickHandler={() => {}}
        submitClickHandler={async ({
          email,
          password,
        }: {
          email: string;
          password: string;
        }) => {
          await auth?.signin({
            username: email.toLowerCase(),
            password: password,
          });
        }}
        error={errorLookup(
          auth?.error?.message || (location?.state as any)?.error || error,
        )}
      />
    </>
  );
};
