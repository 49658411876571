import { AuthEvent, AuthProvider, AxiosProvider } from "@wayflyer/auth-sdk";
import { useCallback } from "react";
import { useNavigate, useRoutes, useHref } from "react-router-dom";
import type { AuthListener } from "@wayflyer/auth-sdk";

import * as config from "../../../../config.staff.json";
import { USE_DEV_MODE, USE_STAFF_MODE } from "../../../utils/env";
import { isLocalhost } from "../../../utils/host";
import { ROUTES } from "../sharedRoutes";

import { staffRoutes } from "./staffRoutes";

export const StaffRoutes = () => {
  const rendered = useRoutes(staffRoutes);

  const navigate = useNavigate();
  const goToStaffApp = useCallback(() => {
    navigate(ROUTES.REDIRECT, { replace: true });
  }, [navigate]);

  const cookieDomain = USE_DEV_MODE
    ? "internal.wayflyerdev.com"
    : isLocalhost()
      ? "localhost"
      : USE_STAFF_MODE
        ? "wayflyer.team"
        : window.location.host;

  const listeners: AuthListener[] = USE_DEV_MODE
    ? [
        {
          event: AuthEvent.SIGNIN_SUCCESS,
          callback: async () => {
            goToStaffApp();
          },
          name: "staff-login-dev-mode",
        },
      ]
    : [
        {
          event: AuthEvent.OAUTH_COMPLETE,
          callback: async () => {
            goToStaffApp();
          },
          name: "staff-login",
        },
      ];

  const oAuthRedirect = useHref(ROUTES.OAUTH);
  return (
    <AuthProvider
      config={{
        ...config,
        authApiBaseUrl: window.location.origin,
        cookieDomain,
        oauthRedirectUrl: `${window.location.origin}${oAuthRedirect}`,
      }}
      listeners={() => {
        return listeners;
      }}
      withSessionRefresher={false}
    >
      <AxiosProvider>
        <>{rendered}</>
      </AxiosProvider>
    </AuthProvider>
  );
};
